
import { QuestionFormDefault, QuestionFormType } from "../types/question/question-type";
import { Options, Vue } from "vue-class-component";
import Category from "../components/Category.vue";
import { questionPostHandler } from "../handlers/question/question-handlers";
import { CategoryType } from "../types/helpers/category-type";
import { courseListCategoryHandler } from "../handlers/course/category-handlers";
import { CheckCircleIcon } from "@heroicons/vue/outline";
import { AlertSeverities, showAlert } from "@/helpers/alerts";

@Options({
  components: {
    Category,
    CheckCircleIcon,
  },
})
export default class QuestionNew extends Vue {
  question: QuestionFormType = QuestionFormDefault;

  private category_list: Array<CategoryType> | undefined = [];

  async mounted(): Promise<void> {
    courseListCategoryHandler(parseInt(this.$route.params.course_id.toString(), 10))
      .then((category_list: Array<CategoryType> | void) => {
        this.category_list = category_list || [];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async handleSubmit(): Promise<void> {
    const payload = this.question;
    const response = await questionPostHandler(payload, parseInt(this.$route.params.course_id.toString(), 10));
    if (response === undefined) return;
    showAlert("Odesláno", "Otázka byla úspěšně odeslána.", AlertSeverities.success);
    await this.$router.push({
      name: "QuestionList",
      params: { course: this.$route.params.course, year: this.$route.params.year, course_id: this.$route.params.course_id },
    });
  }

  addCategory(category_id: number): void {
    const index = this.question.category.indexOf(category_id);
    if (index === -1) {
      this.question.category.push(category_id);
    } else {
      this.question.category.splice(index, 1);
    }
  }
}
