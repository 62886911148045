import { AlertSeverities, showAlert } from "@/helpers/alerts";
import { request } from "@/requests/api-request";
import { CategoryColourPatchType, CategoryFormType, CategoryNamePatchType, CategoryType } from "@/types/helpers/category-type";
import { AxiosError } from "axios";
import { getHeaderAuth } from "../handlers-helpers";

export async function courseListCategoryHandler(course_id: number): Promise<Array<CategoryType> | void> {
  return await request(undefined, "GET", `course/${course_id}/category`, undefined, undefined, undefined)
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response.payload;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
    });
}

export async function coursePatchCategoryHandler(payload: CategoryNamePatchType | CategoryColourPatchType, course_id: number): Promise<null> {
  return await request(undefined, "PATCH", `course/${course_id}/category`, undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
        switch (error.response?.status) {
          case 401:
            showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
            break;
          case 403:
            showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
            break;
        }
      }
    });
}

export async function coursePostCategoryHandler(payload: CategoryFormType, course_id: number): Promise<null> {
  return await request(undefined, "POST", `course/${course_id}/category`, undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
        switch (error.response?.status) {
          case 401:
            showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
            break;
          case 403:
            showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
            break;
        }
      }
    });
}

export async function courseDeleteCategoryHandler(course_id: number): Promise<null> {
  return await request(undefined, "DELETE", `course/${course_id}/category`, undefined, undefined, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
        switch (error.response?.status) {
          case 401:
            showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
            break;
          case 403:
            showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
            break;
        }
      }
    });
}
